/* global fullpage, global, $ */

const $innerBoxes = $(
  '.article-content [data-module] .image-assoc-box .image-assoc-inner',
);
let clearTimer = null;

const checkVisibles = () => {
  clearTimeout(clearTimer);
  clearTimer = setTimeout(() => {
    const $visible = $innerBoxes.filter((i, el) => $(el).visible());
    if ($visible.length > 1) {
      $visible
        .addClass('fade-out-v')
        .last()
        .removeClass('fade-out-v');
    } else {
      $visible.removeClass('fade-out-v');
    }
  }, 10);
};

if ($innerBoxes.length > 1) {
  $innerBoxes
    .addClass('fade-out-v')
    .first()
    .removeClass('fade-out-v');
}

setInterval(checkVisibles, 200);

function createFullpage() {
  /* eslint-disable-next-line */
  new fullpage('#fullpage', {
    licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
    navigation: false,
    css3: true,
    fitToSectionDelay: 10,
    normalScrollElements: '.sharable, .main-mobile-menu',
    normalScrollElementTouchThreshold: 6,
    responsiveWidth: 768,
    responsiveAutoHeightWidth: 768,
    navigationPosition: 'left',
    menu: '.menuOverlay',
    // scrollOverflow: true,
    afterRender: function() {
      $('#fp-nav').addClass('rendered');
      $('#fullpage').addClass('rendered');
      if (window.hasLocalStorage) {
        if (window.location.hash === '#back') {
          const lastSlide = localStorage.getItem('fp_section');
          // console.log({ lastSlide, x });
          window.fullpage_api.silentMoveTo(lastSlide);
        } else {
          localStorage.removeItem('fp_section');
        }
      }
    },
    afterLoad: function(section, destination) {
      // origin, destination, direction
      console.log(section, destination);
      if (window.hasLocalStorage && section && destination.anchor) {
        localStorage.setItem('fp_section', destination.anchor);
      }
      if (destination.index > 0) {
        $('body').addClass('slide-scroll');
        $('body').addClass('slide-annimation');
      }
      if (destination.index === 0) {
        $('body').removeClass('slide-scroll');
      }
    },
    onLeave: function() {
      $('body').removeClass('slide-annimation');
      if ($innerBoxes.length > 1) {
        checkVisibles();
      }
    },
    afterSlideLoad: function() {
      if ($innerBoxes.length > 1) {
        checkVisibles();
      }
    },
    afterResize: function() {
      if ($innerBoxes.length > 1) {
        checkVisibles();
      }
    },
    onSlideLeave: function() {
      if ($innerBoxes.length > 1) {
        checkVisibles();
      }
    },
  });
}
createFullpage();

$(document).ready(() => {
  if (window.hasLocalStorage) {
    const lastSlide = localStorage.getItem('fp_section');
    if ($('.close-article').length && lastSlide) {
      $('.close-article').each((i, el) => {
        el.hash = lastSlide;
      });
    }
  }
});

$('.article-content [data-module] .image-assoc-box').each(function() {
  const $module = $(this);
  const $scroller = $module.closest('.sharable');
  const $side = $('.image-side .row');
  $scroller.on('scroll', () => {
    if (window.innerWidth > 767) {
      if ($module.visible()) {
        setTimeout(() => $side.addClass('fade-out'), 50);
      } else {
        $side.removeClass('fade-out');
      }
    } else if ($side.hasClass('fade-out')) {
      $side.removeClass('fade-out');
    }
  });
});

$('body')
  .on('click', 'a.scroll-crmr', (e) => {
    e.preventDefault();
    window.fullpage_api.moveSectionDown();
  })
  .on('click', '.article-content a.close-article.return', (e) => {
    e.preventDefault();
    window.fullpage_api.moveTo(1);
  });

// $fullPage.on('click', '.more-info', function (e) {
//     e.preventDefault();
//     window.fullpage_api.moveSectionDown();
// });

// $('.to-top').click(function (e) {
//     e.preventDefault();
//     window.fullpage_api.moveTo(1);
// });

// /* eslint-disable-next-line */
// new fullpage('#fullpage', {
//     navigation: true,
// });
