/* eslint no-unused-vars: 0  */
/* global $, Inview, Waypoint */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollOffset = 150;
const scrollTime = 400;

$('body')
  .on('click', 'a.scroll-read-more', function(e) {
    e.preventDefault();
    const scrollTop = $($(this).attr('href')).offset().top - scrollOffset;
    $('html, body').animate({ scrollTop }, scrollTime);
  })
  .on('click', '.smooth_scroll, .smooth-scroll a', function(e) {
    e.preventDefault();
    const $target = $($(this).attr('href')); // usually a #hash selector...

    if ($target.length) {
      const scrollTop = $target.offset().top - scrollOffset;

      // Animate
      $('html, body')
        .stop()
        .animate({ scrollTop }, scrollTime);
    }
  });

// used if we need more control over the hashlinks to account for the nav
// store the hash (DON'T put this code inside the $() function, it has to be executed
// right away before the browser can start scrolling!
const $top = $.$body.find('.top');
const target = window.location.hash;
const navHeight = $top.innerHeight();
// delete hash so the page won't scroll to it
window.location.hash = '';

// now whenever you are ready do whatever you want
// (in this case I use jQuery to scroll to the tag after the page has loaded)
$(window).on('load', () => {
  console.log(target);
  if (target) {
    $('html, body').animate(
      {
        scrollTop: $(`${target}`).offset().top - scrollOffset,
      },
      700,
      'swing',
      () => {},
    );
  }
});

// Timeline Scroll

const scrollEffectDelay = 0; // delay any scroll events by this many MS
const $slide = $('.timeline-side').find('.slide');

// add class to last module
$slide.last().addClass('last-slide');
$slide.first().addClass('first-slide');

// const checkActiveSlide = throttleDomAfterAsync(() => {
//   $slide.each((i, el) => {
//     const $el = $(el);
//     $el.toggleClass('activeSlide', $el.visible(true));
//   });
// }, scrollEffectDelay);
// checkActiveSlide();

// $('.timeline-side').on('scroll', checkActiveSlide);

const $timelineSlide = $('.timeline-side');

$('.scroll-up.btn').on('click', (e) => {
  console.log('clicked');
  e.preventDefault();
  const $nextSlide = $timelineSlide.find('.activeSlide').prev('.slide');
  // console.log($nextSlide);

  if ($nextSlide.length) {
    const slideTop = $nextSlide.position().top + $timelineSlide.scrollTop();
    console.log(slideTop, $nextSlide);
    $timelineSlide.animate(
      {
        scrollTop: slideTop,
      },
      500,
    );
  }
});

$('.scroll-down.btn').on('click', (e) => {
  // console.log($('.activeSlide').offset().top);
  e.preventDefault();
  const $nextSlide = $timelineSlide.find('.activeSlide').next('.slide');
  // console.log($nextSlide);

  if ($nextSlide.length) {
    const slideTop = $nextSlide.position().top + $timelineSlide.scrollTop();
    console.log(slideTop, $nextSlide);
    $timelineSlide.animate(
      {
        scrollTop: slideTop,
      },
      500,
    );
  }
});
