/* global $ */

// Filter <select> boxes
$('.filters select').selectric({
  maxHeight: 200,
  disableOnMobile: false,
  nativeOnMobile: false,
});

// Change peition filter label
$('.sf-field-taxonomy-petition_type .selectric .label').text(
  'Filter by Category',
);

// Change selectric arrow to svgs
$('.selectric .button').replaceWith(
  '<button class="selectric-button"><svg id="plus" viewBox="0 0 17.86 18.77"><path class="cls-1" d="M8.44,10.43H.51V8.49H8.42V.1h2.05V8.49h7.9v1.94H10.49v8.44H8.44Z" transform="translate(-0.51 -0.1)"/></svg></button>',
);

// Add search icon to search and filter pro search input
$('.sf-field-search label').append(
  '<div class="search__icon"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 213.4 214.4" style="enable-background:new 0 0 213.4 214.4;" xml:space="preserve"><path d="M162,138.1c23.2-34.5,19.6-81.6-10.8-112.1C116.6-8.6,60.6-8.7,26,25.9c-34.6,34.6-34.7,90.8-0.1,125.4 c30.2,30.2,76.9,33.8,110.9,11.7l51.5,51.4l25.1-25.1L162,138.1z M126.3,126.1c-20.7,20.7-54.4,20.7-75.2,0 c-20.7-20.7-20.7-54.4,0-75.2c20.7-20.7,54.4-20.7,75.2,0C147,71.6,147,105.4,126.3,126.1z"></path></svg></div>',
);

// Prevent reload on dropdown close
$('.selectric').click((e) => {
  e.preventDefault();
});

// Append a hidden submit button
$('.searchandfilter').append('<button type="submit"></button>');
$('.searchandfilter [type="submit"]').hide();

// Click on appended hidden submit button using the Selectric change event on any filter UI.
// We use the change event to capture keyboard and screenreader users.
// $('.filters select').on('selectric-change', () => {
//   setTimeout(() => {
//     $('.searchandfilter [type="submit"]').click();
//   }, 0);
// });

// Submit search filter pro when a li (list item) is clicked on.
// The ul should appear by default below the .selectric-items div.
$('.selectric-items ul li').click(() => {
  $('.searchandfilter [type="submit"]').click();
});

// Event filter
(function($) {
  $(document).ready(function(){
    //filter by category
    $(document).on('click', '.js-filter-item > a', function(e){
      e.preventDefault();
      const preloaderFadeOutTime = 1000;
      //const preloader = $('.loader-bg');
      let container = $('.cards-wrap');
      let category = $(this).data('category');
      let currentURL = $('html').attr('lang');

      console.log(currentURL);

      $('.js-filter-item').removeClass('active');
      $(this).parent().addClass('active');

      $.ajax({
        type: 'POST',
        url: '/wp-admin/admin-ajax.php',
        data: {
          action: 'filter', //action taken from the filters.php - wp_ajax_filter - filter its the source
          category: category,
          currentURL,
        },
        beforeSend:function(xhr){
          //preloader.fadeIn(preloaderFadeOutTime);
          //container.fadeOut(preloaderFadeOutTime);
        },
        success: function(result) {
          container.html(result);
          //$('#totalProducts').text($('.product-item').length);
          $('.cards-wrap').html(result);
          //preloader.fadeOut(preloaderFadeOutTime);
          //container.fadeIn(preloaderFadeOutTime);
        },
        error: function(result){
          console.warn(result);
        }
      });
    });

  });

})(jQuery);
