/* global $ */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 70; // delay any scroll events by this many MS
const $dottedNav = $('.dotted-nav');

if ($dottedNav.length) {
  // this seems like a lot of work to change one thing white on scroll
  const checkWhiteClass = throttleDomAfterAsync(() => {
    const windowSize = $(window).height() / 2;
    const scrollPosition = $(window).scrollTop();
    if (scrollPosition >= windowSize) {
      $('.dotted-nav').removeClass('white');
    } else {
      $('.dotted-nav').addClass('white');
    }
  }, scrollEffectDelay);

  $(window).on('scroll', checkWhiteClass);

  // activate dotted nav on load
  const s = $('.module.activeSection').attr('id');
  $dottedNav.find(`a#${s}`).addClass('active');

  // event to check which section is active
  const toggleNavColorScroll = throttleDomAfterAsync(() => {
    const _s = $('.module.activeSection').attr('id');
    $dottedNav.find('a.active').removeClass('active');
    $dottedNav.find(`a#${_s}`).addClass('active');
  }, scrollEffectDelay);

  $(window).on('scroll', toggleNavColorScroll);
}
