/* global $ */
const testEl = document.createElement('x-test');
const supportsWebkitBackgroundClipText =
  typeof testEl.style.webkitBackgroundClip !== 'undefined' &&
  ((testEl.style.webkitBackgroundClip = 'text'),
  testEl.style.webkitBackgroundClip === 'text');

if (supportsWebkitBackgroundClipText) {
  $('html').addClass('supports-background-clip-text');
}
