/* global $ */

let currentID = 1;
$('body')
  .find('.video-share .videoWrapper iframe')
  .each(function() {
    $(this).attr('id', currentID);
    $(this).attr('src', $(this).attr('src') + '&autoplay=0');
    $(this).attr('src', $(this).attr('src') + '&rel=0');
    currentID++;
  });

let current = 1;
$('body')
  .find('.video-cover')
  .each(function() {
    const findVideo = $('#' + current);
    $(this).click(function() {
      $(this).fadeOut();
      $(findVideo).attr(
        'src',
        $(findVideo)
          .attr('src')
          .replace('autoplay=0', 'autoplay=1'),
      );
    });
    current++;
  });

$('body')
  .find('.pop-over .videoWrapper iframe')
  .each(function() {
    $(this).attr('id', currentID);
    $(this).attr('src', $(this).attr('src') + '&autoplay=1');
    $(this).attr('src', $(this).attr('src') + '&rel=0');
    currentID++;
  });

$(document).on('click touchstart', (event) => {
  if (!$(event.target).closest('.pop-over .videoWrapper iframe').length) {
    if ($('.pop-over').is(':visible')) {
      $('.pop-over').hide();
      const iFrameSrc = $('.pop-over .videoWrapper iframe').attr('src');
      if (iFrameSrc) {
        $('.pop-over .videoWrapper iframe').attr(
          'src',
          iFrameSrc.replace('autoplay=1', 'autoplay=0'),
        );
      }
    }
  }
});

$('body')
  .find('.play-button')
  .click((event) => {
    if (!$(event.target).closest('.pop-over .videoWrapper iframe').length) {
      if ($('.pop-over').is(':visible')) {
        $('.pop-over').hide();
      }
    }
  });

function playVideo($video) {
  $video
    .prop('autoplay', true)
    .attr('preload', 'auto')
    .removeClass('unplayed');
  const video = $video.get(0);
  if (video && typeof video.play === 'function') {
    video.play();
  }
}

const $videos = $('body').find('video');
function playVideosIfVisible() {
  if ($videos.filter('.unplayed').length) {
    $('body')
      .find('video')
      .filter('.unplayed')
      .each((i, el) => {
        const $video = $(el);
        if ($video.css('display') === 'block') {
          playVideo($video);
        }
      });
  } else {
    $(window).off('resize', playVideosIfVisible);
  }
}

if ($videos.length) {
  $(window).resize(playVideosIfVisible);
  playVideosIfVisible();
}
