/* global $ */

const $body = $('body');
// const $supportersWrapper = $('.module.supporter-module');
// const $supportersWrapperHeight = $supportersWrapper.height();
const $viewAllTools = $('.view-all-tool');

$('.view-all-supporters').on('click', () => {
  $body.find('.supporters-wrapper').addClass('expand');
  $body.find('.supporters-top-tool').addClass('show');
  $viewAllTools.fadeOut();
});
// if ($supportersWrapperHeight > '500') {
// }
