/* global $ */

/* Magical function to detect overlaps */
$.fn.overlaps = function(objs) {
  const elems = { targets: [], hits: [] };
  const $objs = $(objs);
  this.each(function() {
    const bounds = $(this).offset();
    bounds.right = bounds.left + $(this).outerWidth();
    bounds.bottom = bounds.top + $(this).outerHeight();

    $objs.each(function() {
      const obj = this;
      const compare = $(obj).offset();
      compare.right = compare.left + $(obj).outerWidth();
      compare.bottom = compare.top + $(obj).outerHeight();

      if (
        !(
          compare.right < bounds.left ||
          compare.left > bounds.right ||
          compare.bottom < bounds.top ||
          compare.top > bounds.bottom
        )
      ) {
        elems.targets.push(this);
        elems.hits.push(obj);
      }
    });
  });

  return elems;
};

$.fn.visible = function(partial) {
  const $t = $(this);
  const $w = $(window);
  const viewTop = $w.scrollTop() - 72;
  const viewBottom = viewTop + $w.height() - 72;
  const _top = $t.offset().top - 72;
  const _bottom = _top + $t.height() - 72;
  const compareTop = partial === true ? _bottom : _top;
  const compareBottom = partial === true ? _top : _bottom;

  return compareBottom <= viewBottom && compareTop >= viewTop;
};
