/* global $, Cookies */

import { gup } from '../helpers/params';

// Add dynamic sourcing to contribution link
$('header .contribute-link a').each(function() {
  const $this = $(this);
  const source = Cookies.getJSON('source') || gup('source') || 'website';
  const sourcedUrl = $this
    .attr('href')
    .replace('website', source)
    .replace('=homepage', `=${source}`);
  $this.attr('href', sourcedUrl);
});
