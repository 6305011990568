// Utility functions for setting URL parameters.

/**
 * @param {String} searchString
 * @returns {String}
 */
export const extractParameter = (searchString) => (name) => {
  name = String(name).replace(/(\[|\])/g, '\\$1');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(searchString);
  return results === null ? '' : results[1];
};

/**
 * @param {String} parameter name
 * @returns {String} parameter value
 *
 * gup :: String -> String
 */

export const gup = extractParameter(window.location.href);

/**
 * @param {Object}
 * @returns {String}
 */
export const encodeParameterString = (obj = {}) =>
  Object.keys(obj).reduce((string, param) => {
    if (string !== '') {
      string += '&';
    }
    return string.concat(
      encodeURIComponent(param) + '=' + encodeURIComponent(obj[param]),
    );
  }, '');
